// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --bs-body-font-family: \"Rubik\", sans-serif;\n}\n\n.navbar-nav .nav-link.active,\n.navbar-nav .nav-link.show {\n  background-color: #f7af24;\n  padding: 0px 20px;\n  border-radius: 30px;\n}\n\n.about-me {\n  background-color: #fef7e9;\n}\n\n.card:hover {\n  border: 3px solid black;\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,0CAA0C;AAC5C;;AAEA;;EAEE,yBAAyB;EACzB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap\");\n\n:root {\n  --bs-body-font-family: \"Rubik\", sans-serif;\n}\n\n.navbar-nav .nav-link.active,\n.navbar-nav .nav-link.show {\n  background-color: #f7af24;\n  padding: 0px 20px;\n  border-radius: 30px;\n}\n\n.about-me {\n  background-color: #fef7e9;\n}\n\n.card:hover {\n  border: 3px solid black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
